import Head from "next/head";
import Image from "next/image";
import localFont from "next/font/local";
import styles from "@/styles/home.module.css";

const Index = () => {
	return (
		<main id="main" className={styles.main} role="main">
			<section className={styles.section}>
				<div className={styles.staticContent}>
					<Image
						width={2400}
						height={1600}
						src="/homepage-contents/images/block-1/Website_Block1_white.png"
						alt="Block 1"
					/>
				</div>
			</section>
			<section className={styles.section}>
				<div className={styles.staticContent}>
					<Image
						width={2400}
						height={1600}
						src="/homepage-contents/images/block-2/Website_Block2_white.png"
						alt="Block 2"
					/>
				</div>
			</section>
			<section
				className={`${styles.section} ${styles.sectionParallax} ${styles.section03}`}>
				<div className={styles.staticContent}>
					<Image
						width={2400}
						height={1600}
						src="/homepage-contents/images/block-3/Website_Block3_parallax.png"
						alt="Block 3"
					/>
				</div>
			</section>
			<section className={styles.section}>
				<div className={styles.staticContent}>
					<Image
						width={2400}
						height={1600}
						src="/homepage-contents/images/block-4/Website_Block4_white.png"
						alt="Block 4"
					/>
				</div>
			</section>
			<section
				className={`${styles.section} ${styles.sectionParallax}  ${styles.section05}`}>
				<div className={styles.staticContent}>
					<Image
						width={2400}
						height={1600}
						src="/homepage-contents/images/block-5/Websit_Block5_parallax.png"
						alt="Block 5"
					/>
				</div>
			</section>
			<section className={styles.section}>
				<div className={styles.staticContent}>
					<Image
						width={2400}
						height={1600}
						src="/homepage-contents/images/block-6/Website_Block6_white.png"
						alt="Block 6"
					/>
				</div>
			</section>
			<section
				className={`${styles.section} ${styles.sectionParallax}  ${styles.section07}`}>
				<div className={styles.staticContent}>
					<Image
						width={2400}
						height={1600}
						src="/homepage-contents/images/block-7/Website_Block7_parallax.png"
						alt="Block 7"
					/>
				</div>
			</section>
			<section className={styles.section}>
				<div className={styles.staticContent}>
					<Image
						width={2400}
						height={1600}
						src="/homepage-contents/images/block-8/Website_Block8_white.png"
						alt="Block 8"
					/>
				</div>
			</section>
			<section
				className={`${styles.section} ${styles.sectionParallax}  ${styles.section09}`}>
				<div className={styles.staticContent}>
					<Image
						width={2400}
						height={1600}
						src="/homepage-contents/images/block-9/Website_Block9_parallax.png"
						alt="Block 9"
					/>
				</div>
			</section>
			<section className={styles.section}>
				<div className={styles.staticContent}>
					<Image
						width={2400}
						height={1600}
						src="/homepage-contents/images/block-10/Website_Block10_white.png"
						alt="Block 10"
					/>
				</div>
			</section>
			<section className={styles.section}>
				<div className={styles.staticContent}>
					<Image
						width={2400}
						height={1600}
						src="/homepage-contents/images/block-11/Website_Block11_white.png"
						alt="Block 11"
					/>
				</div>
			</section>
			<section className={`${styles.section} ${styles.sectionTedTalk}`}>
				<div className={styles.responsiveContent}>
					<div className={styles.tedtalk_video_container}>
						<div
							style={{
								position: "relative",
								height: 0,
								paddingBottom: "56.25%",
							}}>
							<iframe
								src="https://embed.ted.com/talks/lang/en/tanishia_lavette_williams_the_billion_dollar_problem_in_education"
								width="854"
								height="480"
								style={{
									position: "absolute",
									left: 0,
									top: 0,
									width: "100%",
									height: "100%",
								}}
								frameBorder="0"
								scrolling="no"
								allowFullScreen
								title="Ted Talk"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className={styles.sectionContact}>
				<div className={styles.responsiveContent}>
					<h1 className={styles.contactHeading}>Contact Tanishia</h1>
					<form action="https://formspree.io/f/xpzvrbao" method="POST">
						<div
							className={`${styles.form_section_half} form_section-first_name`}>
							<label className={styles.firstNameField}>First Name:</label>
							<input
								required
								placeholder="First Name"
								type="text"
								name="first-name"
							/>
						</div>
						<div
							className={`${styles.form_section_half} form_section-last_name`}>
							<label className="last-name-field">Last Name:</label>
							<input
								required
								placeholder="Last Name"
								type="text"
								name="last-name"
							/>
						</div>
						<div className={`${styles.form_section} form_section-email`}>
							<label className="email-field">Email:</label>
							<input required placeholder="Email" type="email" name="email" />
						</div>
						<div className={`${styles.form_section} form_section-message`}>
							<label className="message-field">Message:</label>
							<textarea
								required
								placeholder="Message"
								name="message"></textarea>
						</div>
						<div className={`${styles.form_section} form_section-submit`}>
							<button className="submit-button" type="submit">
								SUBMIT
							</button>
						</div>
					</form>
					<div className={styles.socials_footer}>
						<h2 className={styles.socials_footer_heading}>Follow Tanishia</h2>
						<div className={styles.social_icon_list}>
							<a
								className={styles.social_icon_instagram}
								href="https://www.instagram.com/tanishialavette/"
								target="_blank"
								rel="noopener noreferrer">
								<Image
									width={128}
									height={128}
									className="instagram-icon"
									src="/homepage-contents/images/Instagram_Glyph_Gradient.png"
									alt="Instagram"
								/>
							</a>
							<a
								className={styles.social_icon_twitter}
								href="https://twitter.com/tanishialavette"
								target="_blank"
								rel="noopener noreferrer">
								<Image
									width={128}
									height={128}
									className="instagram-icon"
									src="/homepage-contents/images/x-logo-black.webp"
									alt="Twitter"
								/>
							</a>
							<a
								className={styles.social_icon_linkedin}
								href="https://www.linkedin.com/in/tanishialavette/"
								target="_blank"
								rel="noopener noreferrer">
								<Image
									width={128}
									height={128}
									className="instagram-icon"
									src="/homepage-contents/images/LI-In-Bug.webp"
									alt="LinkedIn"
								/>
							</a>
						</div>
					</div>
				</div>
			</section>
			<footer className={styles.legal_footer}>
				<h3 className={styles.legal_footer_heading}>
					© 2024 Tanishia Williams
				</h3>
			</footer>
		</main>
	);
};

export default Index;
